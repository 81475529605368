/* this file is transformed by vux-loader */
/* eslint-disable */
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Group from 'vux/src/components/group';
import Cell from 'vux/src/components/cell';
import Qrcode from 'vux/src/components/qrcode';
import html2canvas from 'html2canvas';
export default {
  name: 'InvitationResult',
  components: {
    Group: Group,
    Cell: Cell,
    Qrcode: Qrcode
  },
  data: function data() {
    return {
      visitType: '',
      visitor: {},
      //来访信息
      register: {},
      //注册信息
      xh: '',
      saveImgUrl: ''
    };
  },
  computed: {},
  methods: {
    drawImage: function drawImage() {
      var _this = this;

      var invitationResult = document.getElementById('allInvitationResult');
      var opts = {
        useCORS: true // 允许图片跨域

      };
      html2canvas(invitationResult, opts).then(function (canvas) {
        /* 此处的base64ImgSrc就是得到的img的base64字符串，直接在页面上显示即可 */
        var base64ImgSrc = canvas.toDataURL('image/png');
        _this.saveImgUrl = base64ImgSrc;

        _this.$utils.Tools.loadHide();
      });
    },
    getVisitorInfo: function getVisitorInfo(xh) {
      var _this2 = this;

      this.$utils.Tools.loadShow();
      this.$api.Visit.selectByPrimaryKey(xh).then(function (response) {
        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this2.$code.success) {
          _this2.$utils.Tools.toastShow(msg);
        } else {
          _this2.visitor = data.visUserEntity;
          _this2.register = data.visRegisterEntity;
          setTimeout(_this2.drawImage, 1000);
        }
      }).catch(function () {
        _this2.$utils.Tools.httpError();
      });
    },
    getVisitType: function getVisitType() {
      var _this3 = this;

      this.$api.Visit.getVisitType().then(function (response) {
        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== 600) {
          _this3.$utils.Tools.toastShow(msg);
        } else {
          _this3.visitType = data;
        }
      }).catch(function () {});
    }
  },
  created: function created() {
    //先执行
    this.getVisitType();
  },
  mounted: function mounted() {
    //后执行
    this.xh = this.$route.query.xh;
    this.getVisitorInfo(this.xh);
  },
  destroyed: function destroyed() {}
};